import React from 'react';
import './category info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Spin,Popconfirm,TreeSelect } from "antd";
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
const { TreeNode } = TreeSelect;

class CategoryInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        categoryName_en:this.props.location.state.data.categoryName_en,
        categoryName_ar:this.props.location.state.data.categoryName_ar,
        type:this.props.location.state.data.type,
        categories:[],
        loading:false,
        refresh:false,
      };

      constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }
      componentDidMount(){
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        }
      }
      
      updateCategory = () => {
        const {categoryName_ar,categoryName_en,type} = this.state
    
        if(hasValue(categoryName_ar)&&hasValue(categoryName_en)){
            let l = message.loading('Wait..', 2.5)
            var data = new FormData()
            data.append('categoryName_ar',categoryName_ar)
            data.append('categoryName_en',categoryName_en)
            data.append('type',type)
            axios.put(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("USER UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }

     delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}categories/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("Row DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
     }
     

    
      submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.updateCategory()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

     
     
      
      paginationButtons = () =>(
        <CardActions style={{display: 'inline-flex'}}>
            <Button 
            onClick={()=>{
              if(this.state.page!==1){
                this.getCategories(1)
                this.setState({page:1})
              }
            }}
            className="pagenation" >{allStrings.first}</Button>
    
            <Button 
            onClick={()=>{
             if(this.state.page>1){
                this.getCategories(this.state.page-1)
                this.setState({page:this.state.page-1})
             }
            }}
            className="pagenation">{allStrings.prev}</Button>
            
            <Pagination
            onChange={(event,page)=>{
            console.log("page   ",page)
            if(page!==this.state.page){
              this.getCategories(page)
              this.setState({page:page})
            }
            
            }} 
            defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#233b5b !important`}} />
            
            <Button 
            onClick={()=>{
              if(this.state.page<this.state.pages){
                this.getCategories(this.state.page+1)
                this.setState({page:this.state.page+1})
              }
            }}
            className="pagenation">{allStrings.next}</Button>
    
            <Button 
             onClick={()=>{
              if(this.state.page!==this.state.pages){
                this.getCategories(this.state.pages)
                this.setState({page:this.state.pages})
              }
            }}
            className="pagenation">{allStrings.last}</Button>
    
        </CardActions>
      )
    render(){
      if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />
      return (
        <div className="App">
          <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#233b5b'}}>
            <MDBContainer>
                <MDBRow>
                    <MDBCol xl ="12" lg = "12" md="12" sm="12">
                        <div className="infoCard">
                            <Card className="editCard">
                            <CardMedia
                            style={{height:"0px"}}
                            />
                            <CardHeader></CardHeader>
                            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                <div className="topIcon">
                                    <span className="material-icons" style={{color:'white',fontSize:23}}>category</span>
                                </div>
                                <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                <p>{this.state.actionType==="VIEW"?allStrings.categoryInfo:allStrings.edit}</p>
                                </div>
                            </div>
                            <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        
                                        <div className="grey-text">
                                            {this.state.actionType==="VIEW"&&
                                            <MDBRow>
                                                
                                                <MDBCol md="12">
                                                    <div className="md-form">
                                                        <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                        <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            }
                                            <MDBRow>
                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                        <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.categoryName_en} type="text" id="form3" name="categoryName_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>

                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                        <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.categoryName_ar} type="text" id="form2" name="categoryName_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}         
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.type}
                                                        value={this.state.type}
                                                        onChange={(value) => {
                                                            console.log(value)
                                                            this.setState({type:value});
                                                        }} 
                                                    
                                                    >
                                                        <TreeNode value="BOTH" title={allStrings.both} key="BOTH" />
                                                        <TreeNode value="COMPOUND" title={allStrings.compound} key="COMPOUND" />
                                                        <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />                                                
                                                        
                                                    </TreeSelect>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                          {this.state.actionType==="EDIT"&&
                                            <MDBBtn
                                            style={{background:'#233b5b',color:'#fff'}}
                                            rounded
                                            type="submit"
                                            className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="edit" className="mr-2" />
                                                {allStrings.updateCategory}        
                                            </MDBBtn>
                                          }{this.state.actionType==="VIEW"&&
                                            <Tooltip  title={allStrings.areYouSureDelete}  placement="top" arrow>
                                              <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                                                onConfirm={()=>{
                                                  this.delete()
                                                }}>
                                                <MDBBtn
                                                  color="red"
                                                  rounded
                                                  style={{ borderRadius: "2px",background:'red' }}
                                                  className="btn-block z-depth-1a"
                                                >
                                                  
                                                  <MDBIcon icon="trash-alt" className="mr-2" />
                                                  {allStrings.remove}
                                                </MDBBtn>
                                              </Popconfirm>
                                            </Tooltip>
                                          }
                                        </div>
                                    </form>
                                    </MDBCol>
                                    
                                </MDBRow>
                            </MDBContainer>
                            </CardContent>
                        </Card>
                    </div>
                    </MDBCol>
                </MDBRow>
              
            </MDBContainer>
          </Spin>
        </div> 
      )
    }
}



const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(CategoryInfo)
);