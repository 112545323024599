import React from 'react';
import './about.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Spin } from "antd";
class About extends React.Component {
    state = {
        actionType:"EDIT",
        aboutUs_en:'',
        aboutUs_ar:'',
        facebook:'',
        instagram:'',
        phone:'',
        website:'',
        about:[],
        loading:true,
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getAbout()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getAbout = () =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}about`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("about   ",response.data)
          this.setState({
            loading:false,
            about:response.data.data,
            facebook:response.data.data.facebook,
            instagram:response.data.data.instagram,
            phone:response.data.data.phone,
            aboutUs_ar:response.data.data.aboutUs_ar,
            aboutUs_en:response.data.data.aboutUs_en,
            website:response.data.data.website,
          })
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
      }
    update = () => {
        const {aboutUs_en,aboutUs_ar,facebook,instagram,phone,website} = this.state
        if(hasValue(aboutUs_ar)&&hasValue(aboutUs_en)){
            let l = message.loading('Wait..', 2.5)
            var data = {
              "aboutUs_en":aboutUs_en,
              "aboutUs_ar":aboutUs_ar,
              "facebook":facebook,
              "instagram":instagram,
              "phone":phone,
              "website":website
            }
            axios.put(`${BASE_END_POINT}about/${this.state.about.id}`,data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("about UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.push('/home')
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ABOUT')) return <Redirect to='/Home' />
  console.log(this.state.social1)
  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#233b5b'}}>
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span className="material-icons" style={{color:'white',fontSize:23}}>info</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.about}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                              <MDBCol md="12">
                              <form
                              className="needs-validation"
                              onSubmit={this.submitHandler}
                              noValidate
                              >
                                  
                                  <div className="grey-text">
                                  
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.aboutUs_en}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  name="aboutUs_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.about.aboutUs_en?this.state.about.aboutUs_en:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.aboutUs_ar}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9]+$/g,'') } name="aboutUs_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form90" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.about.aboutUs_ar?this.state.about.aboutUs_ar:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    
                                    <MDBCol md="6">
                                        <div class="md-form">
                                        <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.phone?this.state.about.phone:""} type="text" id="form645" name="phone" class="form-control" onChange={this.changeHandler} required  validate/>
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.instagram}</label>
                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.instagram?this.state.about.instagram:""} type="text" id="formw2" name="instagram" className="form-control" onChange={this.changeHandler} required validate/>
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.facebook}</label>
                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.facebook?this.state.about.facebook:""} type="text" id="form2" name="facebook" className="form-control" onChange={this.changeHandler} required validate/>
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.website}</label>
                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.about.website?this.state.about.website:""} type="text" id="form2" name="website" className="form-control" onChange={this.changeHandler} required validate/>
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                 
                                  </div>
                                  <br></br>
                                  <br></br>
                                  <div className="text-center">
                                      <MDBBtn
                                      type="submit"
                                      rounded
                                      className="btn-block z-depth-1a"
                                      >
                                      <MDBIcon icon="edit" className="mr-2" />
                                          {allStrings.edit}          
                                      </MDBBtn>
                                      
                                  </div>
                              </form>
                              </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(About)
  );