import React from "react";
import "./guideline info.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message,Popconfirm} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';

class guidelineInfo extends React.Component {
  
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    title:this.props.location.state?this.props.location.state.data.title?this.props.location.state.data.title:'':'',
    description:this.props.location.state?this.props.location.state.data.description?this.props.location.state.data.description:'':'',
    id:this.props.location.state.data.id,

    video: this.props.location.state?this.props.location.state.data.video?this.props.location.state.data.video:null:null,
    videoType:'url',
    videoName:'',
    thumbnail: this.props.location.state?this.props.location.state.data.thumbnail?this.props.location.state.data.thumbnail:null:null,
    thumbnailType:'url',
    thumbnailName:'',

    loading:true,
    refresh:false,
    page:1,
    pages:1,
  };

  constructor(props){
    super(props)

    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  update = () => {
    const {video,description,title,videoType,thumbnail,thumbnailType} = this.state
    if(hasValue(title)){
      let l = message.loading('Wait..', 1.5)
        var data = new FormData()
        if(hasValue(description))
              data.append('description',description)
        data.append('title',title)
        if(video != null &&videoType === 'data'){
            data.append(`video`,video)
        }
        if(thumbnail != null &&thumbnailType === 'data'){
          data.append(`thumbnail`,thumbnail)
      }
       
        //////
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}guidelines/${this.props.location.state.data.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/Guidelines')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  

  delete = () => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}guidelines/${this.props.location.state.data.id}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
    },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.update()
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedHandler = event => {
    this.setState({
    video: event.target.files[0],
    videoName: event.target.files[0].name,
    videoType:'data'
    })
  }
  removeFile = () => {
    this.setState({
      video:null,
      videoName:'',
      videoType:"url"
    });
  }
  fileChangedHandlerThumbnail = event => {
    this.setState({
      thumbnail: event.target.files[0],
      thumbnailName: event.target.files[0].name,
      thumbnailType:'data'
    })
  }
  removeFileThumbnail = () => {
    this.setState({
      thumbnail:null,
      thumbnailName:'',
      thumbnailType:"url"
    });
  }
  
  imageSection = () =>{
      
    return(
      <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
                  <video width="100%" height="300px" controls>
                      <source src={this.state.video} type="video/mp4"/>
                      <source src={this.state.video} type="video/ogg"/>
                      Your browser does not support the video tag.
                  </video>
              </div>
              <Tooltip  title={allStrings.areYouSureDelete}  placement="top" arrow>
                  <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                  onConfirm={()=>{
                      this.delete()
                  }}>
                  <MDBBtn
                      color="red"
                      rounded
                      style={{ borderRadius: "2px",background:'red' }}
                      className="btn-block z-depth-1a"
                  >
                      
                      <MDBIcon icon="trash-alt" className="mr-2" />
                      {allStrings.remove}
                  </MDBBtn>
                  </Popconfirm>
              </Tooltip>
          </div>
      </MDBCol>
        )
  }
  

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('GUIDELINES')) return <Redirect to='/Home' />
    return (
      <div className="App">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
              <div className="infoCard">
                  <Card className="editCard">
                    <CardMedia
                    style={{height:"0px"}}
                    />
                    <CardHeader></CardHeader>
                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                        <div className="topIcon">
                            <span className="material-icons" style={{color:'white',fontSize:23}}>rss_feed</span>
                        </div>
                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                        <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                        </div>
                    </div>
                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            <div className="grey-text">
                            {this.state.actionType==="VIEW"&&
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            }
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                    <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title} type="text" id="form38"  name="title"  className="form-control" onChange={this.changeHandler} required validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form7" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                    <br></br>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    <textarea disabled={this.state.actionType==="VIEW"?true:false}  rows="3" style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} 
                                    defaultValue={this.state.description} type="text" id="form7" className="form-control" onChange={this.changeHandler} validate name="description"/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              {this.state.actionType==="EDIT"&&
                              <MDBRow>
                                <MDBCol md="12">
                                    <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.video}</label>

                                    <div className="fileUpload2"> 
                                        <input  key={this.state.video} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadVideo}</span></span>
                                    </div>
                                    <div className="preview">
                                    {this.state.video&&
                                    <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                      <video className="previewVideo2" controls>
                                          <source crossorigin="anonymous | use-credentials" src={this.state.videoType==="data"?URL.createObjectURL(this.state.video):this.state.video} type="video/mp4"/>
                                          <source crossorigin="anonymous | use-credentials" src={this.state.videoType==="data"?URL.createObjectURL(this.state.video):this.state.video} type="video/ogg"/>
                                          Your browser does not support the video tag.
                                      </video>
                                        <span className="videoName">{this.state.videoName}</span>
                                        <MDBIcon icon="trash" className="mr-2 removeImg2"
                                        onClick={() => this.removeFile()}  ></MDBIcon>
                                    </div>
                                    }
                                    </div>
                                    
                                </MDBCol>
                              </MDBRow>
                              }
                              {this.state.actionType==="EDIT"&&
                                <MDBRow>
                                    <MDBCol md="12">
                                        <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.thumbnail}</label>

                                        <div className="fileUpload2"> 
                                            <input  key={this.state.thumbnail} type='file' className="upImgs2" onChange={this.fileChangedHandlerThumbnail} ></input>
                                            <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                        </div>
                                        <div className="preview">
                                        {this.state.thumbnail&&
                                        <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                            <img crossorigin="anonymous"alt="" src={this.state.thumbnailType==="data"?URL.createObjectURL(this.state.thumbnail):this.state.thumbnail}  className="previewImg2"/>
                                            <span className="imgName">{this.state.thumbnailName}</span>
                                            <MDBIcon icon="trash" className="mr-2 removeImg2"
                                            onClick={() => this.removeFileThumbnail()}  ></MDBIcon>
                                        </div>
                                        }
                                        </div>
                                        
                                    </MDBCol>
                                </MDBRow>
                              }
                            </div>
                            <br></br>
                            <br></br>
                            {this.state.actionType==="EDIT"&&
                            <div className="text-center">
                              <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                                <MDBIcon icon="edit" className="mr-2" />
                                {allStrings.update}
                              </MDBBtn>
                            </div>
                            }
                            
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchaddressrops = {
    setUser,
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchaddressrops)(guidelineInfo)
  );