import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './views.css'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import { withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import { CSVLink } from "react-csv";

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
const { RangePicker } = DatePicker;

let headers = [
  { label: "#", key: "id" },
  { label: "User", key: "user" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Duration", key: "duration" },
  { label: "Property", key: "property" },
  { label: "Project", key: "project" },
  { label: "Category", key: "category" },
  { label: "Location", key: "location" },
  { label: "Date", key: "date" },
];
 
let csvData = [
  
];
class Views extends React.Component {
  
  state={
    views:[],
    views2:[],
    loading:true,
    refresh:false,
    count:0,
    page:1,
    pages:1,
    search:null,
    projects:[],
    categories:[],
    locations:[],
    project:null,
    category:null,
    location:null,
    showFilter:false,
    startDate:null,
    endDate:null,
    csvData:[],
    isReady:false
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    let pageNum = this.state.page
    if(sessionStorage.getItem('pageName') === "VIEWS"){
      pageNum = sessionStorage.getItem('pageNum');
      this.setState({page:pageNum})
    }
    this.getViews(pageNum,false)
    this.getViewsWithoutPagenation()
    this.getCategory()
    this.getlocation()
    this.getprojects()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getprojects = () => {
    axios.get(`${BASE_END_POINT}projects/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({projects:response.data.data})
    })
    .catch(error=>{
      console.log("ALL projects ERROR")
      console.log(error.response)
    })
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data.data})
    })
    .catch(error=>{
      console.log("ALL categories ERROR")
      console.log(error.response)
    })
  }
  getlocation = () => {
    axios.get(`${BASE_END_POINT}location/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({locations:response.data.data})
    })
    .catch(error=>{
      console.log("ALL locations ERROR")
      console.log(error.response)
    })
  }
  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}views/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("views DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getViews(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
  getViews = (page,refresh) =>{
    this.setState({loading:true})
    let {search,project,category,location,startDate,endDate} = this.state
    let url = `${BASE_END_POINT}views?page=${page}`
    
    
    if(refresh === true){
      this.setState({project:null,category:null,location:null})
    }else{
      if(search !== null){
        let str = '&search='+ search
        url += str
      }
      if(project !== null){
        let str = '&project='+ project
        url += str
      }
      if(category !== null){
        let str = '&category='+ category
        url += str
      }
      if(location !== null){
        let str = '&location='+ location
        url += str
      }
      if(startDate !== null && endDate !== null){
        let str = '&startDate='+ startDate+'&endDate=' + endDate
        url += str
      }
    }
    axios.get(url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
     })
    .then(response=>{
       console.log("Views   ",response.data)
      var data = response.data.data.map((item,index)=>{
      return  [
        item.id?item.id:"",
        item.user?item.user.fullname?item.user.fullname.substr(0,20):"":"",
        item.user?item.user.email?item.user.email:"":"",
        item.user?item.user.phone?item.user.phone:"":"",
        item.property?item.property:"",
        item.duration?item.duration:"0",
        item.project?{type:'tag',value:item.project.name.substr(0,20)}:{type:'tag',value:'غير معروف'},
        item.category?{type:'tag',value:item.category.categoryName.substr(0,20)}:{type:'tag',value:'غير معروف'},
        item.location?{type:'tag',value:item.location.name.substr(0,20)}:{type:'tag',value:'غير معروف'},
        item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):"",
        "action"
      ]
      })  
      this.setState({
        loading:false,
        refresh:false,
        views:response.data.data,
        views2:data,
        count:response.data.totalCount,
        page:response.data.page,
        pages:response.data.pageCount,
      })
      sessionStorage.setItem('pageNum', page);
      sessionStorage.setItem('pageName', "VIEWS");
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getViewsWithoutPagenation = () =>{
    let {project,category,location,startDate,endDate} = this.state
    let url = `${BASE_END_POINT}views/withoutPagenation/get?all=true`
    if(project !== null){
      let str = '&project='+ project
      url += str
    }
    if(category !== null){
      let str = '&category='+ category
      url += str
    }
    if(location !== null){
      let str = '&location='+ location
      url += str
    }
    if(startDate !== null && endDate !== null){
      let str = '&startDate='+ startDate+'&endDate=' + endDate
      url += str
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }
    })
    .then(response=>{
      console.log("views   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{user:item.user?item.user.fullname?item.user.fullname:"":""},
            ...{email:item.user?item.user.email?item.user.email:"":""},
            ...{phone:item.user?item.user.phone?item.user.phone:"":""},
            ...{duration:item.duration?item.duration:"0"},
            ...{property:item.property?item.property.title?item.property.title.substr(0,20):"":""},
            ...{project:item.project?item.project.name.substr(0,20):'غير معروف'},
            ...{category:item.category?item.category.categoryName.substr(0,20):'غير معروف'},
            ...{location:item.location?item.location.name.substr(0,20):'غير معروف'},
            ...{date:item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):""}            
          };
        });
        this.setState({csvData:csvData,isReady:true})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getViews(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getViews(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getViews(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#659FF1'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getViews(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getViews(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  onChange = (date, dateString) =>{
    //this.setState({startDate:dateString[0], endDate:dateString[1]})
    if(this.props.isRTL){
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD"),
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD") ,
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
      console.log(moment(dateString[0]).locale('en').format("YYYY-MM-DD") )

    }
    console.log( dateString);
  }
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('VIEWS')) return <Redirect to='/Home' />
    return (
        <div className="App">
            
        <MDBContainer> 
        <MDBRow>
            <MDBCol md="12">
            <div className="screenTable">
            <Table
                settings = {
                <div>
                    <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                    <span 
                    style={{position: 'relative',padding: '4px',zIndex:'44',color: '#233b5b',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                    onClick={() => {this.getViews(1,true)}}
                    class="material-icons">
                    replay
                    </span>
                    {!this.state.isReady?
                    <img crossorigin="anonymous"alt="" style={{padding: '0px',position: 'relative',top: '-5px',marginLeft:'-15px'}} src={require('../../assets/images/loading.gif')} width="70"></img>
                    :
                    <CSVLink 
                    data={this.state.csvData} 
                    headers={headers} 
                    filename={"views.csv"}
                    style={{padding: '10px',position: 'relative',top: '-5px'}}
                    >
                    <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                    </CSVLink>
                    }
                </div>
                
                }
                filter ={
                    <div>
                        <div className={this.state.showFilter?'showClassC':'hideClass'}>
                        <MDBRow>
                            <MDBCol xl="6" lg="6" md="6" sm="12">
                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.project}</label>
                                <TreeSelect virtual={false}
                                    showSearch={true}
                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                    treeCheckable = {false}         
                                    treeNodeFilterProp="title"                      
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder={allStrings.project}
                                    value={this.state.project}
                                    showCheckedStrategy= {SHOW_PARENT}
                                    onChange={(value) => {
                                    console.log(value)
                                    this.setState({project:value});
                                    
                                    }} 
                                
                                >
                                    {this.state.projects.map(val=>(
                                        <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                    ))}
                                </TreeSelect>
                            </MDBCol>
                            <MDBCol xl="6" lg="6" md="6" sm="12">
                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.location}</label>
                                <TreeSelect virtual={false}
                                    showSearch={true}
                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                    treeCheckable = {false}
                                    treeNodeFilterProp="title"                      
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder={allStrings.location}
                                    value={this.state.location}
                                    showCheckedStrategy= {SHOW_PARENT}
                                    onChange={(value) => {
                                    console.log(value)
                                    this.setState({location:value});
                                    }} 
                                
                                >
                                    {this.state.locations.map(val=>(
                                        <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                    ))}
                                </TreeSelect>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol xl="6" lg="6" md="6" sm="12">
                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                <TreeSelect virtual={false}
                                    showSearch={true}
                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                    treeCheckable = {false}         
                                    treeNodeFilterProp="title"                      
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder={allStrings.category}
                                    value={this.state.category}
                                    showCheckedStrategy= {SHOW_PARENT}
                                    onChange={(value) => {
                                    console.log(value)
                                    this.setState({category:value});
                                    }} 
                                
                                >
                                    {this.state.categories.map(val=>(
                                        <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                    ))}
                                </TreeSelect>
                            </MDBCol>
                            <MDBCol xl="6" lg="6" md="6" sm="12">
                                <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                                    <RangePicker format={"YYYY-MM-DD"} placeholder={[allStrings.startDate,allStrings.endDate]} onChange={this.onChange} />
                                </ConfigProvider>
                            </MDBCol> 
                        </MDBRow>
                        <MDBRow>
                            <MDBCol xl="12" lg="12" md="12" sm="12">
                                <Button style={{background: '#00B483',color: '#fff',width: '100%',margin: '32px auto'}} 
                                onClick={()=>{this.getViews(1)}}><SearchOutlined /> {allStrings.Search}</Button>
                            </MDBCol>
                        </MDBRow>
                        </div>
                    </div>
                }
                enableEdit={false}
                title={allStrings.views}
                icon='pageview'
                data={this.state.views}
                data2={this.state.views2}
                tableColumns={["#",allStrings.client,allStrings.email,allStrings.phone,allStrings.property,allStrings.duration,allStrings.project,allStrings.category,allStrings.location,allStrings.createdAt,allStrings.action]}
                loading={this.state.loading}
                //noDelete={true}
                deleteButton={(id)=>{this.delete(id)}}
                path='/viewsInfo'
                dataCount={this.state.count}
                actionIndex={7}
                />
                {this.paginationButtons()}
                
                </div>
            </MDBCol>
        </MDBRow>
        </MDBContainer>
        </div> 
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Views)
);
