import React from 'react';
import './add company.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { TreeNode } = TreeSelect;
class AddCompany extends React.Component {
    state = {
        name_ar:'',
        name_en:'',
        type:'BOTH',
        selectedFile: null,
        logo: null,
        logoName:'',
        logosType:'data',
    };

    constructor(props){
        super(props)
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
    }
    componentDidMount(){
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        }
    }
    addCompany = () => {
        const {name_ar,logo,name_en,type} = this.state
        if(hasValue(name_en) && hasValue(name_ar)){

          let l = message.loading('Wait..', 1.5)
            var data = new FormData()
            data.append('name_en',name_en)
            data.append('name_ar',name_ar)
            data.append('logo',logo);
            data.append('type',type);
            axios.post(`${BASE_END_POINT}companies`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`
            },
            })
            .then(response=>{
            console.log("add company  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    
    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.addCompany()
    };
    
    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

     
    fileChangedHandler = event => {
        this.setState({
            logo: event.target.files[0],
            logoName: event.target.files[0].name,
            logoType:'data'
        })
    }
    removeFile = () => {
        this.setState({
            logo:null,
            logoName:'',
            logoType:"url"
        });
    }
      
     
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('COMPANIES')) return <Redirect to='/Home' />
    return (
        <div className="App">
            
            <MDBContainer>
                <MDBRow>
                    <MDBCol xl ="12" lg = "12" md="12" sm="12">
                        <div className="infoCard">
                            <Card className="editCard">
                            <CardMedia
                            style={{height:"0px"}}
                            />
                            <CardHeader></CardHeader>
                            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                <div className="topIcon">
                                    <span class="material-icons" style={{color:'white',fontSize:23}}>spa</span>
                                </div>
                                <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                <p>{allStrings.add}</p>
                                </div>
                            </div>
                            <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        <div className="grey-text">
                                            <MDBRow>
                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_en} type="text" id="form3" name="name_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>

                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                        <input onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')}
                                                        style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.name_ar} type="text" id="form2" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}         
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.type}
                                                        value={this.state.type}
                                                        onChange={(value) => {
                                                            console.log(value)
                                                            this.setState({type:value});
                                                        }} 
                                                    
                                                    >
                                                        <TreeNode value="BOTH" title={allStrings.both} key="BOTH" />
                                                        <TreeNode value="COMPOUND" title={allStrings.compound} key="COMPOUND" />
                                                        <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />                                                
                                                        
                                                    </TreeSelect>
                                                </MDBCol>
                                            </MDBRow>
                                            <br></br>
                                            <br></br>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                    <div className="fileUpload2"> 
                                                        <input  key={this.state.logo} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                    </div>
                                                    <div className="preview">
                                                    {this.state.logo&&
                                                    <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                        <img alt="" src={URL.createObjectURL(this.state.logo)}  className="previewImg2"/>
                                                        <span className="imgName">{this.state.logoName}</span>
                                                        <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                        onClick={() => this.removeFile()}  ></MDBIcon>
                                                    </div>
                                                    }
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                            <MDBBtn
                                            type="submit" style={{background:'#233b5b'}}
                                            rounded
                                            className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.addCompany}        
                                            </MDBBtn>
                                        </div>
                                    </form>
                                    </MDBCol>
                                    
                                </MDBRow>
                            </MDBContainer>
                            </CardContent>
                        </Card>
                    </div>
                </MDBCol>
                </MDBRow>
                
                
            </MDBContainer>
        
        </div> 
    )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddCompany)
  );