
import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './deals.css'
import {message,TreeSelect} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
//import 'moment/locale/ar';
import {SearchOutlined} from '@ant-design/icons';

import moment from 'moment'

const { TreeNode } = TreeSelect;
let headers = [
  { label: "#", key: "id" },
  { label: "ClientName", key: "clientName" },
  { label: "ClientPhone", key: "clientPhone" },
  { label: "IspName", key: "ispName" },
  { label: "IspPhone", key: "ispPhone" },
  { label: "SalesName", key: "salesName" },
  { label: "SalesPhone", key: "salesPhone" },
  { label: "DeveloperName", key: "developerName" },
  { label: "Value", key: "value" },
  { label: "Status", key: "status" },
  { label: "Date", key: "date" },
];
 
let csvData = [
  
];
class Deals extends React.Component {
  
  state={
    deals:[],
    deals2:[],
    csvData:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    collapsed: false,
    search:null,
    filterType:{},
    status:null,
    user:this.props.location.state?this.props.location.state.owner:null,
    value:'',
  }

  constructor(props){
    super(props)
    this.getDealsWithoutPagenation()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    let pageNum = this.state.page
    if(sessionStorage.getItem('pageName') === "DEALS"){
      pageNum = sessionStorage.getItem('pageNum');
      this.setState({page:pageNum})
    }
    this.getDeals(pageNum)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
 

  getDeals = (page,rest) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}deals?page=${page}`
    let {status,search,user} = this.state
    if(status !== null){
      let str = '&status='+ status
      url += str
    }
    if(user !== null){
      let str = '&user='+ user
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    console.log(url)
    if(rest) url = `${BASE_END_POINT}deals?page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("deals   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.clientName?item.clientName :'',
        item.clientPhone?item.clientPhone :'',
        item.salesName?item.salesName :'',
        item.salesPhone?item.salesPhone :'',
        item.value?item.value :'',
        item.user?item.user.fullname :'',
        item.status === "PENDING"?{type:'tag',value:allStrings.pending}:item.status === "ACCEPTED"?{type:'tag',value:allStrings.accepted}:{type:'tag',value:allStrings.refused},
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        deals:response.data.data,
        deals2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
      sessionStorage.setItem('pageNum', page);
      sessionStorage.setItem('pageName', "DEALS");
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getDealsWithoutPagenation = (rest) =>{
    let url = `${BASE_END_POINT}deals/withoutPagenation/get?page=1`
    let {status,search,user} = this.state
    if(status !== null){
      let str = '&status='+ status
      url += str
    }
    if(user !== null){
      let str = '&user='+ user
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}deals/withoutPagenation/get`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("deals   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{clientName:item.clientName?item.clientName:''},
            ...{clientPhone:item.clientPhone?item.clientPhone:''},
            ...{ispName:item.user?item.user.fullname:''},
            ...{ispPhone:item.user?item.user.phone:''},
            ...{salesName:item.salesName?item.salesName:''},
            ...{salesPhone:item.salesPhone?item.salesPhone :''},
            ...{developerName:item.developerName?item.developerName:''},
            ...{value:item.value?item.value :''},
            ...{status:item.status === "PENDING"?allStrings.pending:item.status === "ACCEPTED"?allStrings.accept:allStrings.refused},
            ...{date:moment(item.createdAt).format('lll')},
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}deals/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("Row DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getDeals(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addUser">
        <Fab
          style={{background:'#233b5b',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{dACCEPTEDlay: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getDeals(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getDeals(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getDeals(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} 
        style={{color:`#233b5b !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getDeals(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getDeals(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('DEALS')) return <Redirect to='/Home' />

    return (
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          
          <Table
            enableEdit = {this.props.currentUser.user.type === "ADMIN"?true:false}
            title={allStrings.Deals}
            icon='content_paste'
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.setState({search:null,status:null});this.getDeals(1,true);this.getDealsWithoutPagenation(true)}}
                class="material-icons">
                  replay
                </span>
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"deals.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass160':'hideClass'}>
                  <MDBRow>
                    <MDBCol xl="6" lg="6" md="6" sm="12">
                      <div class="md-form">
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.Search}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                      
                      <TreeSelect virtual={false}
                          showSearch={false}
                          value={this.state.status}
                          style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                          treeCheckable = {false}         
                          treeNodeFilterProp="title"                      
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder={allStrings.status}
                          onChange={(value) => {
                          console.log(value)
                          this.setState({status:value});
                          }} 
                      
                      >
                          <TreeNode value="ACCEPTED" title={allStrings.accept} key="ACCEPTED" />
                          <TreeNode value="PENDING" title={allStrings.pending} key="PENDING" />
                          <TreeNode value="REFUSED" title={allStrings.refused} key="REFUSED" />
                        
                      </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                  <Button style={{background: '#233b5b',color: '#fff',width: '100%',margin: '32px auto'}} 
                        onClick={()=>{this.getDeals(1);this.getDealsWithoutPagenation()}}><SearchOutlined /> {allStrings.Search}</Button>
                    
                  </MDBRow>
                  
                </div>
              </div>
            }
            data={this.state.deals}
            data2={this.state.deals2}
            tableColumns={["#",allStrings.clientName,allStrings.clientPhone,allStrings.salesName,allStrings.salesPhone,allStrings.value,allStrings.isp,allStrings.status,allStrings.action]}loading={this.state.loading}

            deleteButton={(id)=>{this.delete(id)}}
            path='/DealInfo'
            actionIndex={8}
            />
            {this.paginationButtons()}
            {/*this.addNewRecordButton()*/}
              
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    );
  }
 

}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDACCEPTEDatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDACCEPTEDatchToProps)(Deals)
);



