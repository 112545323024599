import React from 'react';
import { Layout } from 'antd';
import SideMenu from '../component/sideMenu/sideMenu';
//import Footer from './Footer';
import './layout.css'
import OurHeader from '../component/header/header';

const { Content,Header,Footer } = Layout;

const LayoutWithRoute = ({ children ,marginLeft,marginRight}) => {
  return (
    <Layout style={{ minHeight: '100vh',flexDirection: 'row',marginLeft:marginLeft?marginLeft:0,marginRight:marginRight?marginRight:0 }}>
      <SideMenu />
      <Layout>
        <Header className="site-layout-background" style={{ padding: '0px' }}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:0,width:'100%',marginBottom:30,padding: '0px 0px'}} >
          <OurHeader></OurHeader>
          {/* <span style={{fontWeight:'bold',fontSize:17, color:'#6C757D'}}>Dashboard</span>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                
              <Button style={{minWidth:50}}>
                  <Badge badgeContent={4} color="error">
                    <span className="material-icons" style={{marginTop:-2, color:'#6C757D',fontSize:23}}>notifications</span>
                  </Badge>
              </Button>

              
              <Link to='/Profile'>
              <Button style={{minWidth:50}}>
                <span className="material-icons" style={{color:'#6C757D',fontSize:23}}>person</span>
              </Button>
              </Link>
              
              <Button color="#6c757d">Logout</Button>
              
            </div>
    */}
        </div> 
        </Header>
        <Content style={{ margin: '20px 16px' }}>{children}</Content>
        
        <Footer style={{ textAlign: 'center' }}>DashBoard ©2022 Created by BYIT Company</Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutWithRoute;