import React from 'react';
import './add location.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect } from "antd";
const { TreeNode } = TreeSelect;

class AddLocation extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        type:'BOTH'
       
    };

    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {name_en,name_ar,type} = this.state
        let l = message.loading('Wait..', 2.5)
        
        if(hasValue(name_en)&&hasValue(name_ar)){
            
           
            var data = {
                'name_en':name_en,
                'name_ar':name_ar,
                'type':type
            }
           
            axios.post(`${BASE_END_POINT}location`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`
            },
            })
            .then(response=>{
            console.log("AddLocation UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('LOCATIONS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span class="material-icons" style={{color:'white',fontSize:23}}>location_city</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.AddLocation}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                value={this.state.type}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({type:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="BOTH" title={allStrings.both} key="BOTH" />
                                                <TreeNode value="COMPOUND" title={allStrings.compound} key="COMPOUND" />
                                                <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />                                                
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        style={{background:'#233b5b',color:'#fff'}}
                                        rounded
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddLocation)
  );