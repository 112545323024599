import React from 'react';
import './send notifs.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import Button from '@material-ui/core/Button';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { message,Empty ,TreeSelect} from "antd";
import Tooltip from '@material-ui/core/Tooltip';
import { Edit } from '@material-ui/icons';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class SendNotif extends React.Component {
    state = {
        description:'',
        title:'',
        notifs:[],
        page:1,
        userType:'all',
        users:[],
        usersIds:null,
        modal:false,
    };

    constructor(props){
        super(props)
        this.getNotifs(1)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
     }
     getUsersWithoutPagenation = (type) =>{
        let url = `${BASE_END_POINT}quickSeach/get`
        if(type !=="ALL"){
            url = `${BASE_END_POINT}quickSeach/get?type=${type}`
        }
        
        axios.get(url,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("USERS   ",response.data)

           this.setState({users:response.data.data})
           
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
        })
      }
    SendNotif = () => {
        const {description,title,userType,usersIds} = this.state
        if(hasValue(description)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'description':description,
                'title':title
            }
            if(usersIds == null){
                if(userType !== 'all'){
                    data.userType = userType
                }
            }else{
                data.users = usersIds
            }
            
           
            console.log(data)
            axios.post(`${BASE_END_POINT}notif/SendNotifs`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("send notif ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    update = (id) => {
        console.log("id : "+id)
        const {description,title} = this.state
        if(hasValue(title)&&hasValue(description)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'description':description,
                'title':title
            }
            axios.put(`${BASE_END_POINT}notif/${id}/update`,JSON.stringify(data),{
                headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
                },
            })
            .then(response=>{
                console.log("update notif ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.getNotifs(1)
                this.setState({modal:false,title:'',description:''})
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    getNotifs = (page) =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}notif/getNotifs/get?page=${page}`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Notifs   ",response.data)
          this.setState({
            loading:false,
            refresh:false,
            notifs:response.data.data,
            page:response.data.page,
            pages:response.data.pageCount,
          })
          console.log('nor',response.data.data[0])
          console.log('rev',Object.keys(response.data.data[0]).reverse())
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
    
    }

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.SendNotif()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    paginationButtons = () =>(
        <CardActions style={{display: 'inline-flex'}}>
            <Button 
            onClick={()=>{
              if(this.state.page!==1){
                this.getNotifs(1)
                this.setState({page:1})
              }
            }}
            className="pagenation" >{allStrings.first}</Button>
    
            <Button 
            onClick={()=>{
             if(this.state.page>1){
                this.getNotifs(this.state.page-1)
                this.setState({page:this.state.page-1})
             }
            }}
            className="pagenation">{allStrings.prev}</Button>
            
            <Pagination
            onChange={(event,page)=>{
            console.log("page   ",page)
            if(page!==this.state.page){
              this.getNotifs(page)
              this.setState({page:page})
            }
            
            }} 
            defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#233b5b !important`}} />
            
            <Button 
            onClick={()=>{
              if(this.state.page<this.state.pages){
                this.getNotifs(this.state.page+1)
                this.setState({page:this.state.page+1})
              }
            }}
            className="pagenation">{allStrings.next}</Button>
    
            <Button 
             onClick={()=>{
              if(this.state.page!==this.state.pages){
                this.getNotifs(this.state.pages)
                this.setState({page:this.state.pages})
              }
            }}
            className="pagenation">{allStrings.last}</Button>
    
        </CardActions>
    )
    modal = () =>(
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
              <MDBModalBody>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="12">
                      <form style={{paddingTop:'1rem'}}
                        className="needs-validation"
                        onSubmit={this.submitHandlerUpdate}
                        noValidate
                      >
                        <div className="grey-text">
                            <MDBRow>
                                <MDBCol md="12">
                                    <div className="md-form">
                                    <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                    <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.description} type="text" id="form645" name="description" className="form-control" onChange={this.changeHandler} required  validate/>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12">
                                    <div className="md-form">
                                    <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                    <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title} type="text" id="form64" name="title" className="form-control" onChange={this.changeHandler} required  validate/>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="text-center">
                          <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                            <MDBIcon className="mr-2" />
                            {allStrings.update}
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
            </MDBModalBody>
          </MDBModal>
    );
    submitHandlerUpdate = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.update(this.state.updatedId)
    };
    toggle = () => {
        this.setState({
          modal: !this.state.modal,
        });
    }
   
    render(){
        if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('SETTINGS')) return <Redirect to='/Home' />
        return (
            <div className="App">
                <MDBContainer>
                    {this.modal()} 
                    <MDBRow>  
                        <MDBCol md="12">
                            <div className="infoCard">
                                <Card className="editCard">
                                    <CardMedia
                                    style={{height:"0px"}}
                                    />
                                    <CardHeader></CardHeader>
                                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                        <div className="topIcon">
                                            <span className="material-icons" style={{color:'white',fontSize:23}}>circle_notifications</span>
                                        </div>
                                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                        <p>{allStrings.SendNotif}</p>
                                        </div>
                                    </div>
                                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                                        <MDBContainer>
                                            
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <form
                                                    className="needs-validation"
                                                    onSubmit={this.submitHandler}
                                                    noValidate
                                                >
                                                    <div className="grey-text">
                                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                                            <MDBCol md="6">
                                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                                                <TreeSelect 
                                                                    virtual={false}
                                                                    showSearch={false} 
                                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                                    treeCheckable = {false}         
                                                                    treeNodeFilterProp="title"                      
                                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                    placeholder={allStrings.userType}
                                                                    onChange={(value) => {
                                                                    console.log(value)
                                                                    this.setState({userType:value,users:[]});
                                                                    this.getUsersWithoutPagenation(value)
                                                                    }} 
                                                                
                                                                >
                                                                    <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                                                                    <TreeNode value="ADMIN" title={allStrings.admin} key="ADMIN" />
                                                                    <TreeNode value="SUB-ADMIN" title={allStrings.subAdmin} key="SUB-ADMIN" />
                                                                    <TreeNode value="ISP" title={allStrings.client} key="ISP" />
                                                                    
                                                                    
                                                                </TreeSelect>
                                                            </MDBCol>
                                                            {this.state.userType !== 'all'&&
                                                            <MDBCol md="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.users}</label>
                                                            <TreeSelect 
                                                                virtual={false}
                                                                showSearch={true} 
                                                                maxTagCount="6"
                                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                                treeCheckable = {true}        
                                                                showCheckedStrategy= {SHOW_PARENT}   
                                                                treeNodeFilterProp="title"                      
                                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                placeholder={allStrings.users}
                                                                onChange={(value) => {
                                                                console.log(value)
                                                                    this.setState({usersIds:value});
                                                                    
                                                                }} 
                                                            
                                                            >
                                                                
                                                                {this.state.users.map(val=>(
                                                                    <TreeNode value={val.id} title={val.fullname} key={val.id} />
                                                                ))}
                                                            </TreeSelect>
                                                            </MDBCol>
                                                            }
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md="12">
                                                                <div className="md-form">
                                                                <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                                                <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.description} type="text" id="form645" name="description" className="form-control" onChange={this.changeHandler} required  validate/>
                                                                </div>
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md="12">
                                                                <div className="md-form">
                                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                                                <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title} type="text" id="form64" name="title" className="form-control" onChange={this.changeHandler} required  validate/>
                                                                </div>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="text-center">
                                                    <MDBBtn
                                                        type="submit" style={{background:'#233b5b',color:'#fff'}}
                                                        rounded
                                                        className="btn-block z-depth-1a"
                                                        >
                                                        <MDBIcon icon="plus" className="mr-2" />
                                                            {allStrings.SendNotif}          
                                                        </MDBBtn>
                                                    </div>
                                                </form>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </CardContent>
                                </Card>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="12">
                            <div className="infoTable">
                                <div className="topInfoTable" style={{ background: "#233b5b" }}>
                                    <h4>
                                    <span className="material-icons" style={{color:'white',fontSize:23}}>circle_notifications</span>{ allStrings.notifs}
                                    </h4>
                                </div>
                                <div className="tableDate">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                    <thead>
                                        {this.props.isRTL?
                                        <tr style={{textAlign:'center'}}>
                                            <th>{allStrings.more}</th>
                                            <th>{allStrings.description}</th>
                                            <th>{allStrings.title}</th>
                                            <th scope="col">#</th>
                                            
                                        </tr>
                                        :
                                        <tr style={{textAlign:'center'}}>
                                            <th scope="col">#</th>
                                            <th>{allStrings.title}</th>
                                            <th>{allStrings.description}</th>
                                            <th>{allStrings.more}</th>
                                        </tr>
                                            
                                        }
                                        
                                    </thead>
                                    <tbody>
                                    {this.state.loading?
                                        <tr style={{height: '200px'}}>
                                            <th colSpan={this.state.tableType === "collected" ?"5":"4"} className="noData">
                                            <Skeleton height={400}/> 
                                            </th>
                                        </tr>
                                        :
                                        this.state.notifs.length > 0 ?
                                        this.state.notifs.map(val =>(
                                            this.props.isRTL?
                                            <tr  style={{textAlign:'center'}}>
                                                <td>
                                                    <span onClick={()=>{
                                                        this.toggle();this.setState({updatedId:val.id})
                                                    }} style={{margin: "4px",cursor:'pointer'}}>
                                                        <Tooltip title={allStrings.edit} placement="top" arrow>
                                                            <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                                        </Tooltip>
                                                    </span> 
                                                </td>
                                                <td>{val.description_ar}</td>
                                                <td>{val.title_ar}</td>
                                                <td >{val.id}</td>
                                            </tr>
                                            :
                                            <tr  style={{textAlign:'center'}}>
                                                <td >{val.id}</td>
                                                <td>{val.title_en}</td>
                                                <td>{val.description_en}</td>
                                                <td>
                                                    <span onClick={()=>{
                                                        this.toggle();this.setState({updatedId:val.id})
                                                    }} style={{margin: "4px",cursor:'pointer'}}>
                                                        <Tooltip title={allStrings.edit} placement="top" arrow>
                                                            <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                                        </Tooltip>
                                                    </span> 
                                                </td>
                                            </tr>
                                    
                                        ))
                    
                                        :
                                        <tr style={{height: '200px'}}>
                                            <th colSpan="4" className="noData">
                                            <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                            </th>
                                        </tr>
                                    }
                                    
                                    
                                        
                                    </tbody>
                                    </table>
                                    {this.state.notifs.length > 0 &&
                                        <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                            {this.paginationButtons()}
                                        </div>
                                    }
                                </div>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    
            </MDBContainer>
            </div> 
        )
    }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});
  
const mapDispatchToProps = {
};
  
export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(SendNotif)
);