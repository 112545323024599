import React from 'react';
import './setting.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message,Spin,DatePicker,TreeSelect } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class Setting extends React.Component {
    state = {
        actionType:'EDIT',
        androidAppVersion:"",
        iosAppVersion:"",
        nawyLogo:null,
        nawyLogoType:'url',
        force:true,
        nawyLogoName:'',
        Setting:[],
        loading:true,
        customerServicePhone: "",
        cilPhone: "",
        contractPhone: "",
        closingSupportPhone: "",
        coveragePhone: "",
        totalDeals:"",
        targetPoints:[{prize:null,point: 0}],
        enableTargetAchievement:true,
        targetAchievementLogo:null,
        targetAchievementLogoType:'url',
        targetAchievementLogoName:"",
        targetAchievementBackground:null,
        targetAchievementBackgroundType:'url',
        targetAchievementBackgroundName:"",
        targetAchievementInfo:null,
        targetAchievemenInfoType:'url',
        targetAchievementInfoName:"",
        pinCode:"",
        prizes:[],
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        this.getSetting()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getPrizes()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getPrizes = () => {
        axios.get(`${BASE_END_POINT}prizes/withoutPagenation/get`,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`
          },
         })
        .then(response=>{
          console.log(response.data)
          this.setState({prizes:response.data.data,loading:false})
        })
        .catch(error=>{
          console.log("ALL prizes ERROR")
          console.log(error.response)
        })
      }
    getSetting = () =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}setting`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Setting   ",response.data)
          let targetPoints = [];
          response.data.data.targetPoints.forEach(async (val) => {
            targetPoints.push({prize:val.prize.id,point:val.point});
          });
          this.setState({
            loading:false,
            Setting:response.data.data,
            androidAppVersion:response.data.data.androidAppVersion,
            iosAppVersion:response.data.data.iosAppVersion,
            force:response.data.data.force,
            nawyLogo:response.data.data.nawyLogo,
            customerServicePhone:response.data.data.customerServicePhone,
            cilPhone:response.data.data.cilPhone,
            contractPhone:response.data.data.contractPhone,
            closingSupportPhone:response.data.data.closingSupportPhone,
            coveragePhone:response.data.data.coveragePhone,
            totalDeals:response.data.data.totalDeals,
            targetStartDate:response.data.data.targetStartDate,
            targetEndDate:response.data.data.targetEndDate,
            targetPoints:targetPoints.length > 0?targetPoints:[{prize:null,point: 0}],
            enableTargetAchievement:response.data.data.enableTargetAchievement,
            targetAchievementLogo:response.data.data.targetAchievementLogo,
            targetAchievementBackground:response.data.data.targetAchievementBackground,
            targetAchievementInfo:response.data.data.targetAchievementInfo
          })
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
      }
    update = () => {
        const {pinCode,androidAppVersion,iosAppVersion,nawyLogo,force,customerServicePhone,cilPhone,contractPhone,
            closingSupportPhone,coveragePhone,nawyLogoType,totalDeals,enableTargetAchievement,
            targetStartDate,targetEndDate,targetPoints,targetAchievementLogo,targetAchievementLogoType,
            targetAchievementInfo,targetAchievementInfoType,targetAchievementBackground,targetAchievementBackgroundType} = this.state
            let l = message.loading('Wait..', 2.5);
            var data = new FormData()
            data.append('androidAppVersion',androidAppVersion)
            data.append('iosAppVersion',iosAppVersion)
            data.append('force',force)
            data.append('customerServicePhone',customerServicePhone)
            data.append('cilPhone',cilPhone)
            data.append('contractPhone',contractPhone)
            data.append('closingSupportPhone',closingSupportPhone)
            data.append('coveragePhone',coveragePhone)
            data.append('totalDeals',totalDeals)
            data.append('enableTargetAchievement',enableTargetAchievement)
            data.append('targetStartDate',targetStartDate)
            data.append('targetEndDate',targetEndDate)
            if(pinCode !== "" && pinCode !== undefined){
                data.append('pinCode',pinCode)
            }
            if(targetPoints.length > 0){
                data.append('targetPoints',JSON.stringify([...new Set(this.state.targetPoints.filter(e => e.prize != null))]))
            }
            if(targetAchievementLogo && targetAchievementLogoType === "data"){
                data.append('targetAchievementLogo',targetAchievementLogo)
            }
            if(targetAchievementInfo && targetAchievementInfoType === "data"){
                data.append('targetAchievementInfo',targetAchievementInfo)
            }
            if(targetAchievementBackground && targetAchievementBackgroundType === "data"){
                data.append('targetAchievementBackground',targetAchievementBackground)
            }
            if(nawyLogo && nawyLogoType === "data"){
                data.append('nawyLogo',nawyLogo)
            }
            axios.put(`${BASE_END_POINT}setting/${this.state.Setting.id}`,data,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':`Bearer ${this.props.currentUser.token}`
                },
            })
            .then(response=>{
            console.log("Setting UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    fileChangedHandler = event => {
        this.setState({
            nawyLogo: event.target.files[0],
            nawyLogoName: event.target.files[0].name,
            nawyLogoType:'data'
        })
    }
    removeFile = () => {
        this.setState({
            nawyLogo:null,
            nawyLogoName:'',
            nawyLogoType:"url"
        });
    }
    targetAchievementLogoChangedHandler = event => {
        console.log(event.target.files[0])
        this.setState({
            targetAchievementLogo: event.target.files[0],
            targetAchievementLogoName: event.target.files[0].name,
            targetAchievementLogoType:'data'
        })
    }
    removeTargetAchievementLogo = () => {
        this.setState({
            targetAchievementLogo:null,
            targetAchievementLogoName:'',
            targetAchievementLogoType:"url"
        });
    }
    targetAchievementBackgroundChangedHandler = event => {
        this.setState({
            targetAchievementBackground: event.target.files[0],
            targetAchievementBackgroundName: event.target.files[0].name,
            targetAchievementBackgroundType:'data'
        })
    }
    removeTargetAchievementBackground = () => {
        this.setState({
            targetAchievementBackground:null,
            targetAchievementBackgroundName:'',
            targetAchievementBackgroundType:"url"
        });
    }
    targetAchievementInfoChangedHandler = event => {
        this.setState({
            targetAchievementInfo: event.target.files[0],
            targetAchievementInfoName: event.target.files[0].name,
            targetAchievementInfoType:'data'
        })
    }
    removeTargetAchievementInfo = () => {
        this.setState({
            targetAchievementInfo:null,
            targetAchievementInfoName:'',
            targetAchievementInfoType:"url"
        });
    }
    onChangeTargetStartDate = (date, dateString) =>{
        this.setState({targetStartDate:dateString})
        console.log( dateString);
    }
    onChangeTargetEndDate = (date, dateString) =>{
        this.setState({targetEndDate:dateString})
        console.log( dateString);
    }
    theTargetPoints(){
        return this.state.targetPoints.map((v, i) =>
            <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}> 
                
                <MDBCol md={this.state.actionType ==="EDIT"?"5":"6"}>
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.prize}</label>
                    <TreeSelect virtual={false}
                        showSearch={true}
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeCheckable = {false}
                        treeNodeFilterProp="title"   
                        disabled={this.state.actionType==="EDIT"?false:true}                   
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.prize}
                        value={v.prize}
                        showCheckedStrategy= {SHOW_PARENT}
                        onChange={(value) => {
                            console.log(value)
                            let targetPoints = [...this.state.targetPoints];
                            targetPoints[i].prize = value;
                            this.setState({ targetPoints });
                        }} 
                                                  
                        >
                        {this.state.prizes.map(val=>(
                            <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                        ))}
                    </TreeSelect>
                </MDBCol>
                <MDBCol md={this.state.actionType ==="EDIT"?"5":"6"}>
                    <div className="md-form">
                        <label htmlFor="form2"style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.value}</label>
                        <input value={v.point} disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                        type="number" id="form2" name="point" className="form-control" 
                        onChange={(event)=>{
                            let targetPoints = [...this.state.targetPoints];
                            targetPoints[i].point = event.target.value;
                            this.setState({ targetPoints });
                        }} required validate/>
                        <div class="valid-feedback">{allStrings.correct}</div>
                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                <MDBCol md="2">
                    {i>0&& 
                    <div className="minusInput" onClick={()=>this.removeTargetPointsInput(i)}>
                    <MDBIcon icon="trash" className="mr-2" />           
                    </div>
                    }
                    {i===this.state.targetPoints.length-1&& 
                    <div className="addInputInner"  onClick={()=>this.setState(prevState => ({ targetPoints: [...prevState.targetPoints, {prize:null,point: 0}]})) }>
                    <MDBIcon icon="plus" className="mr-2" />           
                    </div>
                    }
                </MDBCol>
            </MDBRow>   

        )
    }
      
    removeTargetPointsInput = (i) =>{
        let targetPoints = [...this.state.targetPoints];
        targetPoints.splice(i,1);
        this.setState({ targetPoints });
    } 
   
  render(){
    console.log(this.state.targetAchievementLogoType)
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('SETTINGS')) return <Redirect to='/Home' />
  return (
    <div className="App">
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#007AC0'}}>
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span class="material-icons" style={{color:'white',fontSize:23}}>info</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.setting}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.androidAppVersion}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.androidAppVersion:0} type="text"  id="form5" name="androidAppVersion" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form55" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.iosAppVersion}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.Setting?this.state.Setting.iosAppVersion:0} type="text" id="form55" name="iosAppVersion" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.customerService}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.customerServicePhone} type="text" id="form645" name="customerServicePhone" class="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cilPhone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.cilPhone} type="text" id="form56" name="cilPhone" class="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contractPhone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.contractPhone} type="text" id="form645" name="contractPhone" class="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.closingSupportPhone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.closingSupportPhone} type="text" id="form56" name="closingSupportPhone" class="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.coveragePhone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.coveragePhone} type="text" id="form645" name="coveragePhone" class="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.dealCount}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.totalDeals} type="text" id="form645" name="totalDeals" class="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.pinCode}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.pinCode} type="text" id="form645" name="pinCode" class="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                                <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.targetStartDate}</label>
                                                <DatePicker disabled={this.state.actionType==="VIEW"?true:false} style={{width: '100%',marginTop:'15px'}} 
                                                onChange={this.onChangeTargetStartDate} format={'YYYY-MM-DD'} value={this.state.targetStartDate?moment(this.state.targetStartDate, 'YYYY-MM-DD'):""}/>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                                <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.targetEndDate}</label>
                                                <DatePicker disabled={this.state.actionType==="VIEW"?true:false} style={{width: '100%',marginTop:'15px'}} 
                                                onChange={this.onChangeTargetEndDate} format={'YYYY-MM-DD'} value={this.state.targetEndDate?moment(this.state.targetEndDate, 'YYYY-MM-DD'):""}/>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.forceUpdate}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                disabled={this.state.actionType==="VIEW"?true:false} 
                                                value={this.state.force}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.force}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({force:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value={true} title={allStrings.yes} key={true} />
                                                <TreeNode value={false} title={allStrings.no} key={false} />

                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.enableTargetAchievement}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                disabled={this.state.actionType==="VIEW"?true:false} 
                                                value={this.state.enableTargetAchievement}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.enableTargetAchievement}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({enableTargetAchievement:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value={true} title={allStrings.yes} key={true} />
                                                <TreeNode value={false} title={allStrings.no} key={false} />

                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%',marginBottom:'25px',marginTop:'25px'}}>
                                        <span style={{padding:'10px',background:'#233b5b',borderBottomLeftRadius: '14px',color:'#fff',marginTop:'25px'}}>{allStrings.targetPoints}</span>
                                    </label>
                                    {this.theTargetPoints()}
                        
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.nawyApp}</label>
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.nawyLogo&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img crossorigin="anonymous"alt="" src={this.state.nawyLogoType==="data"?URL.createObjectURL(this.state.nawyLogo):this.state.nawyLogo}  className="previewImg2"/>
                                                <span className="imgName">{this.state.nawyLogoName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.targetAchievementLogo}</label>
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.targetAchievementLogo} type='file' className="upImgs2" onChange={this.targetAchievementLogoChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.targetAchievementLogo&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img crossorigin="anonymous"alt="" src={this.state.targetAchievementLogoType==="data"?URL.createObjectURL(this.state.targetAchievementLogo):this.state.targetAchievementLogo}  className="previewImg2"/>
                                                <span className="imgName">{this.state.targetAchievementLogoName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeTargetAchievementLogo()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.targetAchievementBackground}</label>
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.targetAchievementBackground} type='file' className="upImgs2" onChange={this.targetAchievementBackgroundChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.targetAchievementBackground&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img crossorigin="anonymous"alt="" src={this.state.targetAchievementBackgroundType==="data"?URL.createObjectURL(this.state.targetAchievementBackground):this.state.targetAchievementBackground}  className="previewImg2"/>
                                                <span className="imgName">{this.state.targetAchievementBackgroundName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeTargetAchievementBackground()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.targetAchievementInfo}</label>
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.targetAchievementInfo} type='file' className="upImgs2" onChange={this.targetAchievementInfoChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.targetAchievementInfo&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img crossorigin="anonymous"alt="" src={this.state.targetAchievementInfoType==="data"?URL.createObjectURL(this.state.targetAchievementInfo):this.state.targetAchievementInfo}  className="previewImg2"/>
                                                <span className="imgName">{this.state.targetAchievementInfoName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeTargetAchievementInfo()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                        </MDBCol>
                                    </MDBRow>

                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        type="submit" style={{background:'#007AC0',color:'#fff'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.edit}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Setting)
  );