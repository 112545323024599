import React from 'react';
import './question info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
class QuestionInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        description:this.props.location.state.data.description,
        question_en:this.props.location.state.data.question_en,
        question_ar:this.props.location.state.data.question_ar,
        answer_ar:this.props.location.state.data.answer_ar,
        answer_en:this.props.location.state.data.answer_en,
       
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    updateQuestion = () => {
        const {question_en,question_ar,answer_ar,answer_en} = this.state
        let l = message.loading('Wait..', 2.5)
      
        if(hasValue(question_en)&&hasValue(question_ar)&&hasValue(answer_ar)&&hasValue(answer_en)){

            var data = {
                'question_en':question_en,
                'question_ar':question_ar,
                'answer_ar':answer_ar,
                'answer_en':answer_en,
            }
           
            axios.put(`${BASE_END_POINT}questions/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("Question UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }

    deleteQuestion = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}questions/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("Question DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateQuestion()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
    render(){
        if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FAQ')) return <Redirect to='/Home' />

        return (
            <div className="App">
                <MDBContainer> 
                    <MDBRow>  
                        <MDBCol md="12" >
                            <div className="infoCard">
                                <Card className="editCard">
                                    <CardMedia
                                    style={{height:"0px"}}
                                    />
                                    <CardHeader></CardHeader>
                                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                        <div className="topIcon">
                                            <span className="material-icons" style={{color:'white',fontSize:23}}>help_outline</span>
                                        </div>
                                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                        <p>{this.state.actionType==="VIEW"?allStrings.QuestionInfo:allStrings.edit}</p>
                                        </div>
                                    </div>
                                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                                        <MDBContainer>
                                            
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <form
                                                className="needs-validation"
                                                onSubmit={this.submitHandler}
                                                noValidate
                                                >
                                                    
                                                    <div className="grey-text">
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishQuestion}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.question_en} type="text" id="form645" name="question_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicQuestion}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.question_ar} type="text" id="form56" name="question_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                    <MDBCol md="12">
                                                        <div className="md-form">
                                                        <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishAnswer}</label>
                                                        <br></br>
                                                        <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') } name="answer_en" onChange={this.changeHandler} value={this.state.answer_en} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate   />
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                    
                                                    </MDBRow>
                                                    <MDBRow>
                                                    <MDBCol md="12">
                                                        <div className="md-form">
                                                        <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicAnswer}</label>
                                                        <br></br>
                                                        <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} name="answer_ar" onChange={this.changeHandler} value={this.state.answer_ar} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate   />
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                    
                                                    </MDBRow>
                                                
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="text-center">
                                                    {this.state.actionType==="EDIT"&&
                                                        <MDBBtn
                                                        style={{background:'#233b5b',color:'#fff'}}
                                                        rounded
                                                        color="#233b5b"
                                                        type="submit"
                                                        className="btn-block z-depth-1a"
                                                        >
                                                            <MDBIcon icon="edit" className="mr-2" />
                                                            {allStrings.update}          
                                                        </MDBBtn>
                                                    }
                                                        <MDBBtn onClick={()=>{this.deleteQuestion()}} rounded color="danger" className="btn-block z-depth-1a">
                                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                                            {allStrings.remove}
                                                        </MDBBtn>
                                                        
                                                    </div>
                                                </form>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </CardContent>
                                </Card>
                            </div>
                        </MDBCol> 
                    </MDBRow>
                </MDBContainer>
            
            </div> 
        )
    }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(QuestionInfo)
);