import React from 'react';
import './add permission.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";

class Addpermission extends React.Component {
    state = {
        permission_en:'',
        permission_ar:'',
        showSettings:false,
        showDashboard:false,
        showContactUs:false,
        showDeals:false,
        showProjects:false,
        showCountries:false,
        showAbout:false,
        showLocations:false,
        showUsers:false,
        showCategories:false,
        showFaq:false,
        showTeams:false,
        showPermissions:false,
        showSubAdmin:false,
        showProperties:false,
        showViews:false,
        showAddUser:false,
        showClients:false,
        showCompanies:false,
        showAnoncements:false,
        showNewLaunches:false,
        showPrize:false
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {permission_en,permission_ar,showProperties,showDeals
            ,showAbout,showTeams,showSubAdmin,showProjects,showCategories,
            showUsers,showContactUs,showCountries,showFaq,showDashboard,
            showPermissions,showSettings,showLocations,showViews,showAddUser,showClients,
            showCompanies,showAnoncements,showNewLaunches,showPrize} = this.state
        if(hasValue(permission_en)&&hasValue(permission_ar)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'permission_en':permission_en,
                'permission_ar':permission_ar
            }
            let permissions =[];
            if(showPrize ===true){
              permissions.push('PRIZES')
            }
            if(showCompanies ===true){
              permissions.push('COMPANIES')
            }
            if(showAnoncements ===true){
              permissions.push('ANONCEMENTS')
            }
            if(showNewLaunches ===true){
              permissions.push('NEW-LAUNCHES')
            }
            if(showViews ===true){
              permissions.push('VIEWS')
            }
            if(showClients ===true){
              permissions.push('CLIENTS')
            }
            if(showAddUser ===true){
              permissions.push('ADD-USER')
            }
            if(showProperties ===true){
                permissions.push('PROPERTIES')
            }
            if(showDeals ===true){
                permissions.push('DEALS')
            }
            if(showUsers ===true){
                permissions.push('USERS')
            }
            if(showAbout ===true){
                permissions.push('ABOUT')
            }
            if(showTeams ===true){
                permissions.push('TEAMS')
            }
            if(showSubAdmin ===true){
                permissions.push('SUB-ADMIN')
            }
            if(showProjects ===true){
                permissions.push('PROJECTS')
            }
            if(showCategories ===true){
                permissions.push('CATEGORIES')
            }
            if(showContactUs ===true){
                permissions.push('CONTACT-US')
            }
            if(showCountries ===true){
                permissions.push('COUNTRIES')
            }
            if(showFaq ===true){
                permissions.push('FAQ')
            }
            if(showDashboard ===true){
                permissions.push('DASHBOARD')
            }
            if(showPermissions ===true){
                permissions.push('PERMISSIONS')
            }
            if(showSettings ===true){
                permissions.push('SETTINGS')
            }
            if(showLocations ===true){
                permissions.push('LOCATIONS')
            }
            
            data.pages = permissions
            axios.post(`${BASE_END_POINT}permissions`,data,{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("Addpermission UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    
      submitHandler = event => {
          event.preventDefault();
          console.log(event.target.className)
          event.target.className += " was-validated";
          this.add()
      };

      changeHandler = event => {
          this.setState({ [event.target.name]: event.target.value });
      };
      showPrizeOn =()=>{
        this.setState({
          showPrize: true,
        })
      }
      showPrizeOff =()=>{
        this.setState({
          showPrize: false,
        })
      }
      showCompaniesOn =()=>{
        this.setState({
          showCompanies: true,
        })
      }
      showCompaniesOff =()=>{
        this.setState({
          showCompanies: false,
        })
      }
      showAnoncementsOn =()=>{
        this.setState({
          showAnoncements: true,
        })
      }
      showAnoncementsOff =()=>{
        this.setState({
          showAnoncements: false,
        })
      }
      showNewLaunchesOn =()=>{
        this.setState({
          showNewLaunches: true,
        })
      }
      showNewLaunchesOff =()=>{
        this.setState({
          showNewLaunches: false,
        })
      }

      showAddUserOn =()=>{
        this.setState({
          showAddUser: true,
        })
      }
      showAddUserOff =()=>{
        this.setState({
          showAddUser: false,
        })
      }
      showClientsOn =()=>{
        this.setState({
          showClients: true,
        })
      }
      showClientsOff =()=>{
        this.setState({
          showClients: false,
        })
      }
      showViewsOn =()=>{
        this.setState({
          showViews: true,
        })
      }
      showViewsOff =()=>{
        this.setState({
          showViews: false,
        })
      }
      showSettingsOn =()=>{
        this.setState({
          showSettings: true,
        })
      }
      showSettingsOff =()=>{
        this.setState({
          showSettings: false,
        })
      }
      showDashboardOn =()=>{
        this.setState({
          showDashboard: true,
        })
      }
      showDashboardOff =()=>{
        this.setState({
          showDashboard: false,
        })
      }
      showContactUsOn =()=>{
        this.setState({
          showContactUs: true,
        })
      }
      showContactUsOff =()=>{
        this.setState({
          showContactUs: false,
        })
      }
      showDealsOn =()=>{
        this.setState({
          showDeals: true,
        })
      }
      showDealsOff =()=>{
        this.setState({
          showDeals: false,
        })
      }
      showProjectsOn =()=>{
        this.setState({
          showProjects: true,
        })
      }
      showProjectsOff =()=>{
        this.setState({
          showProjects: false,
        })
      }
      showCountriesOn =()=>{
        this.setState({
          showCountries: true,
        })
      }
      showCountriesOff =()=>{
        this.setState({
          showCountries: false,
        })
      }
      showAboutOn =()=>{
        this.setState({
          showAbout: true,
        })
      }
      showAboutOff =()=>{
        this.setState({
          showAbout: false,
        })
      }
      showLocationsOn =()=>{
        this.setState({
          showLocations: true,
        })
      }
      showLocationsOff =()=>{
        this.setState({
          showLocations: false,
        })
      }
      showOfficesOn =()=>{
        this.setState({
          showOffices: true,
        })
      }
      showOfficesOff =()=>{
        this.setState({
          showOffices: false,
        })
      }
      showUsersOn =()=>{
        this.setState({
          showUsers: true,
        })
      }
      showUsersOff =()=>{
        this.setState({
          showUsers: false,
        })
      }
      showCategoriesOn =()=>{
        this.setState({
          showCategories: true,
        })
      }
      showCategoriesOff =()=>{
        this.setState({
          showCategories: false,
        })
      }
      showFaqOn =()=>{
        this.setState({
          showFaq: true,
        })
      }
      showFaqOff =()=>{
        this.setState({
          showFaq: false,
        })
      }
      showTeamsOn =()=>{
        this.setState({
          showTeams: true,
        })
      }
      showTeamsOff =()=>{
        this.setState({
          showTeams: false,
        })
      }
      showPermissionsOn =()=>{
        this.setState({
          showPermissions: true,
        })
      }
      showPermissionsOff =()=>{
        this.setState({
          showPermissions: false,
        })
      }
      showSubAdminOn =()=>{
        this.setState({
          showSubAdmin: true,
        })
      }
      showSubAdminOff =()=>{
        this.setState({
          showSubAdmin: false,
        })
      }
      showPropertiesOn =()=>{
        this.setState({
          showProperties: true,
        })
      }
      showPropertiesOff =()=>{
        this.setState({
          showProperties: false,
        })
      }
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PERMISSIONS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span class="material-icons" style={{color:'white',fontSize:23}}>lock</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.addPermission}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.permission_en} type="text" id="form645" name="permission_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.permission_ar} type="text" id="form56" name="permission_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL ? 'row-reverse' : 'row'}}>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPropertiesOff}
                                              style={{background:this.state.showProperties?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showProperties?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPropertiesOn}
                                              style={{background:this.state.showProperties?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showProperties?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Properties}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showViewsOff}
                                              style={{background:this.state.showViews?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showViews?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showViewsOn}
                                              style={{background:this.state.showViews?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showViews?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.views}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showSettingsOff}
                                              style={{background:this.state.showSettings?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showSettings?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showSettingsOn}
                                              style={{background:this.state.showSettings?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showSettings?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.setting}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showContactUsOff}
                                              style={{background:this.state.showContactUs?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showContactUs?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showContactUsOn}
                                              style={{background:this.state.showContactUs?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showContactUs?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.contactUs}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showDealsOff}
                                              style={{background:this.state.showDeals?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showDeals?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showDealsOn}
                                              style={{background:this.state.showDeals?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showDeals?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Deals}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showProjectsOff}
                                              style={{background:this.state.showProjects?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showProjects?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showProjectsOn}
                                              style={{background:this.state.showProjects?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showProjects?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.projects}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCountriesOff}
                                              style={{background:this.state.showCountries?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCountries?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCountriesOn}
                                              style={{background:this.state.showCountries?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCountries?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Countries}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAboutOff}
                                              style={{background:this.state.showAbout?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAbout?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAboutOn}
                                              style={{background:this.state.showAbout?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAbout?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.about}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showLocationsOff}
                                              style={{background:this.state.showLocations?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showLocations?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showLocationsOn}
                                              style={{background:this.state.showLocations?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showLocations?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.locations}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                     
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showUsersOff}
                                              style={{background:this.state.showUsers?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showUsers?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showUsersOn}
                                              style={{background:this.state.showUsers?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showUsers?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.users}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCategoriesOff}
                                              style={{background:this.state.showCategories?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCategories?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCategoriesOn}
                                              style={{background:this.state.showCategories?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCategories?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.categories}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showFaqOff}
                                              style={{background:this.state.showFaq?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showFaq?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showFaqOn}
                                              style={{background:this.state.showFaq?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showFaq?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.questions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showTeamsOff}
                                              style={{background:this.state.showTeams?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showTeams?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showTeamsOn}
                                              style={{background:this.state.showTeams?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showTeams?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.byitTeam}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showSubAdminOff}
                                              style={{background:this.state.showSubAdmin?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showSubAdmin?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showSubAdminOn}
                                              style={{background:this.state.showSubAdmin?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showSubAdmin?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.supervisors}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPermissionsOff}
                                              style={{background:this.state.showPermissions?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showPermissions?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPermissionsOn}
                                              style={{background:this.state.showPermissions?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showPermissions?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.permissions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showClientsOff}
                                              style={{background:this.state.showClients?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showClients?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showClientsOn}
                                              style={{background:this.state.showClients?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showClients?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.clients} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCompaniesOff}
                                              style={{background:this.state.showCompanies?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCompanies?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCompaniesOn}
                                              style={{background:this.state.showCompanies?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCompanies?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.companies} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPrizeOff}
                                              style={{background:this.state.showPrize?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showPrize?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPrizeOn}
                                              style={{background:this.state.showPrize?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showPrize?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.prizes} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAnoncementsOff}
                                              style={{background:this.state.showAnoncements?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAnoncements?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAnoncementsOn}
                                              style={{background:this.state.showAnoncements?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAnoncements?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.anoncements} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showNewLaunchesOff}
                                              style={{background:this.state.showNewLaunches?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showNewLaunches?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showNewLaunchesOn}
                                              style={{background:this.state.showNewLaunches?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showNewLaunches?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.newLaunches} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showDashboardOff}
                                              style={{background:this.state.showDashboard?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showDashboard?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showDashboardOn}
                                              style={{background:this.state.showDashboard?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showDashboard?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.dashboard}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAddUserOff}
                                              style={{background:this.state.showAddUser?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAddUser?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAddUserOn}
                                              style={{background:this.state.showAddUser?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAddUser?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.AddUser} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>
                                   
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        style={{background:'#00B483',color:'#fff'}}
                                        rounded
                                        type="submit"
                                        color="#00B483"
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Addpermission)
  );