import React from 'react';
import './add employee.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message ,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
const TreeNode = TreeSelect
const { SHOW_PARENT } = TreeSelect;
class AddEmployee extends React.Component {
    state = {
        fullname:'',
        phone:'',
        age:1,
        ratio:0,
        experience:1,
        specialization_ar:'',
        specialization_en:'',
        strengthPoints_ar:'',
        strengthPoints_en:'',
        proffession_en:'',
        proffession_ar:'',
        country:null,
        countries:[],
        img:null,
        imgName:'',
        imgType:"data",
        companyLogo:null,
        companyLogoName:'',
        companyLogoType:"data"
       
    };

    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getCountries();
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getCountries = () => {
        axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`
            },
           })
        .then(response=>{
          console.log("countries",response.data)
          this.setState({countries:response.data.data})
        })
        .catch(error=>{
          console.log("ALL countries ERROR")
          console.log(error.response)
        })
    }
    add = () => {
        const {ratio,fullname,phone,age,img,experience,specialization_ar,specialization_en
        ,strengthPoints_ar,strengthPoints_en,country,companyLogo,proffession_en,proffession_ar} = this.state
        let l = message.loading('Wait..', 2.5)
        
        if(hasValue(fullname)&&hasValue(phone) &&hasValue(age)&&hasValue(experience) &&
            hasValue(proffession_en)&&hasValue(proffession_ar) &&
            hasValue(specialization_ar)&&hasValue(specialization_en) &&
            hasValue(strengthPoints_en)&&hasValue(strengthPoints_ar) &&
            img !==null &&companyLogo !== null && country !== null){
            
           
            var data = new FormData()
            data.append('age',age)
            data.append('ratio',ratio)
            data.append('phone',phone)
            data.append('fullname',fullname)
            data.append('experience',experience)
            data.append('proffession_en',proffession_en)
            data.append('proffession_ar',proffession_ar)

            data.append('specialization_ar',specialization_ar)
            data.append('specialization_en',specialization_en)
            data.append('strengthPoints_ar',strengthPoints_ar)
            data.append('strengthPoints_en',strengthPoints_en)
            data.append('country',country)
            data.append('companyLogo',companyLogo)
            data.append('img',img)
            axios.post(`${BASE_END_POINT}employees`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`
            },
            })
            .then(response=>{
            console.log(" employee UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    fileChangedHandler = event => {
        this.setState({
          img: event.target.files[0],
          imgName: event.target.files[0].name,
          imgType:"data"
        })
        console.log(this.state.img)
        
    }
    removeFile = () => {
        this.setState({
            img:null,
            imgName:'',
            imgType:"url"
        });
    }
    fileChangedHandlerLogo= event => {
        this.setState({
            companyLogo: event.target.files[0],
            companyLogoName: event.target.files[0].name,
            companyLogoType:"data"
        })
        
    }
    removeFileLogo = () => {
        this.setState({
            companyLogo:null,
            companyLogoName:'',
            companyLogoType:"url"
        });
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
    render(){
        if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('EMPLOYEES')) return <Redirect to='/Home' />

        return (
            <div className="App">
                <MDBContainer> 
                    <MDBRow>  
                        <MDBCol md="12">
                            <div className="infoCard">
                                <Card className="editCard">
                                    <CardMedia
                                    style={{height:"0px"}}
                                    />
                                    <CardHeader></CardHeader>
                                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                        <div className="topIcon">
                                            <span class="material-icons" style={{color:'white',fontSize:23}}>account_circle</span>
                                        </div>
                                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                        <p>{allStrings.add}</p>
                                        </div>
                                    </div>
                                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                                        <MDBContainer>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <form
                                                className="needs-validation"
                                                onSubmit={this.submitHandler}
                                                noValidate
                                                >
                                                    
                                                    <div className="grey-text">
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.name}</label>
                                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.fullname} type="text" id="form645" name="fullname" class="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.ratio}</label>
                                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.ratio} type="number" min={0} id="formn8" className="form-control" name='ratio' onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                                        <MDBCol md="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                                            <TreeSelect
                                                            value= {this.state.country}
                                                            showSearch={true} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}    
                                                            disabled={this.state.actionType==="VIEW"?true:false}     
                                                            treeNodeFilterProp="title"                      
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder={allStrings.country}
                                                            showCheckedStrategy= {SHOW_PARENT}
                                                            onChange={(value) => {
                                                                this.setState({country:value});
                                                                console.log(value)
                                                            }} 
                                                            
                                                            >
                                                                {this.state.countries.map(val=>(
                                                                    <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                                ))}
                                                            </TreeSelect>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                                <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.phone} type="text" id="form2" name="phone" className="form-control" onChange={this.changeHandler} required validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.age}</label>
                                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.age} type="number" min={0} id="formn8" className="form-control" name='age' onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.experience}</label>
                                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.experience} type="number" min={0} id="formn8" className="form-control" name='experience' onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishSpecialization}</label>
                                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.specialization_en} type="text" id="form645" name="specialization_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicSpecialization}</label>
                                                            <input onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.specialization_ar} type="text" id="form56" name="specialization_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishProffession}</label>
                                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.proffession_en} type="text" id="form645" name="proffession_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicProffession}</label>
                                                            <input onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.proffession_ar} type="text" id="form56" name="proffession_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishStrengthPoints}</label>
                                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$#*&()_^&!\\/\s-_]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.strengthPoints_en} type="text" id="form645" name="strengthPoints_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicStrengthPoints}</label>
                                                            <input onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF#.,@%$_^*()/\\ _-]+/g, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.strengthPoints_ar} type="text" id="form56" name="strengthPoints_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.companyLogo}</label>

                                                            <div className="fileUpload2"> 
                                                                <input  key={this.state.companyLogo} type='file' className="upImgs2" onChange={this.fileChangedHandlerLogo} ></input>
                                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                            </div>
                                                            <div className="preview">
                                                            {this.state.companyLogo&&
                                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                                <img crossorigin="anonymous"alt="" src={this.state.companyLogoType==="data"?URL.createObjectURL(this.state.companyLogo):this.state.companyLogo} className="previewImg2"/>
                                                                
                                                                <span className="imgName">{this.state.imgName}</span>
                                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                                onClick={() => this.removeFileLogo()}  ></MDBIcon>
                                                            </div>
                                                            }
                                                            </div>
                                                            
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.profileImg}</label>

                                                            <div className="fileUpload2"> 
                                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                            </div>
                                                            <div className="preview">
                                                            {this.state.img&&
                                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                                <img crossorigin="anonymous"alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                                
                                                                <span className="imgName">{this.state.imgName}</span>
                                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                                            </div>
                                                            }
                                                            </div>
                                                            
                                                        </MDBCol>
                                                    </MDBRow>
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="text-center">
                                                        <MDBBtn
                                                        style={{background:'#233b5b',color:'#fff'}}
                                                        rounded
                                                        type="submit"
                                                        className="btn-block z-depth-1a"
                                                        >
                                                        <MDBIcon icon="plus" className="mr-2" />
                                                            {allStrings.add}          
                                                        </MDBBtn>
                                                    
                                                    </div>
                                                </form>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </CardContent>
                                </Card>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div> 
        )
    }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddEmployee)
  );